<template>
	<div>
		<div class="dialog">
			<el-form ref="ruleForm" label-width="80px">
				<el-form-item label="排序">
					<span class="xx"></span>
					<el-input clearable v-model="ruleForm.sort" placeholder="请输入排序"></el-input>
				</el-form-item>
				<el-form-item label="上级部门">
					<span class="xx">*</span>
					<el-select clearable v-model="ruleForm.parent_id" placeholder="请选择上级部门">
						<el-option v-for="item in tableData" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="名称">
					<span class="xx">*</span>
					<el-input clearable v-model="ruleForm.name" placeholder="请输入名称"></el-input>
				</el-form-item>
				<el-form-item label="状态">
					<span class="xx"></span>
					<el-select v-model="ruleForm.status" placeholder="请选择状态">
						<el-option label="启用" :value="1"></el-option>
						<el-option label="禁用" :value="0"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/county";
	export default {
		props: ['title', 'mid', 'parent_id'],
		data() {
			return {
				ruleForm: {
					sort: 0,
					name: '',
					status: 1,
					parent_id: "",
					type:1
				},
				tableData: []
			}
		},
		mounted() {
			this.ruleForm.parent_id = Number(this.parent_id)
			this.getList()
			if (this.title == '编辑') {
				this.getread()
			}
		},
		methods: {
			// 获取上级列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, {
						page: 1,
						limit: 9999,
						parent_id: 0,
						name: '',
						type:1
					})
					.then((res) => {
						if (res.code == 1) {
							this.tableData = res.data.list
						}
					})
			},
			// 获取详情
			getread() {
				this.$http.post(api.read, {
						id: this.mid
					})
					.then((res) => {
						if (res.code == 1) {
							this.ruleForm = res.data
						}
					})
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
			},
			//新增/编辑
			SubmitForm() {
				if (this.ruleForm.parent_id === '') {
					ElMessage({
						message: '请选择上级部门',
						type: 'warning',
					})
				} else if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入名称',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}
				}
			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 1) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('close')
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 1) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.$emit('close')
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>

<style scoped>
</style>
